import $ from "jquery";
import { Role } from "@/state/helpers";
import moment from "moment";


export default {
    data() {
        return {
            // assets_url:'http://shift-fish.local/storage/',
            // assets_url: 'https://develop.shiftfish.com/storage/',
            // currentUser:this.$store.getters["auth/currentUser"]
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters["auth/currentUser"]
        }
    },


    methods: {
        isInvalid(target) {

            if (target == null || target === "" || typeof target === "undefined") {
                return true;
            } else {
                return false;
            }
        },
        getSafe(fn) {
            try { return fn(); }
            catch (e) { return null }
        },
        isUndefined(target) {
            if (typeof target === "undefined") {
                return true;
            } else {
                return false;
            }
        },
        getDefault(target, default_value = "N/A") {
            if (!this.isInvalid(target)) {
                return target;
            } else {
                return default_value;
            }
        },
        isSuperAdmin() {
            return this.currentUser.type == Role.SuperAdmin;
        },
        isRestaurantOwner() {
            return this.currentUser.type == Role.RestaurantOwner;
        },
        isTeamMember() {
            return this.currentUser.type == Role.TeamMember;
        },
        isManager() {
            return this.currentUser.type == Role.Manager;
        },

        validateForm(form_id) {

            var validate = $("#" + form_id).validate({
                errorElement: 'span'
            });
            validate.form();
            return validate.errorList.length
        },

        redirectAfterDelay(url = null, delay = 2000) {
            setTimeout(
                () => {
                    if (url) {
                        window.location.replace(url);
                    } else {
                        location.reload();
                    }
                },
                delay
            );
        },
        formattedTime(value) {
            // let date = new Date(value);
            // let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            // let offset = date.getTimezoneOffset() / 60;
            // let hours = date.getHours();
            // newDate.setHours(hours - offset);
            // return newDate.toLocaleTimeString();
            const format = "h:ma"
            return moment(value).format(format);
        },

        getCurrentDate() {
            const d = new Date();
            return d.toISOString().substring(0, 10);
        },

        getCurrentTime() {
            let date = new Date();
            let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            let offset = date.getTimezoneOffset() / 60;
            let hours = date.getHours();
            newDate.setHours(hours - offset);
            return newDate.toISOString().substring(11, 16);
        },

        getDayName(dateString) {
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var d = new Date(dateString);
            return days[d.getDay()];
        },

        getDayNumberByName(dayName) {
            let dayNumber;
            switch (dayName) {
                case 'Sunday':
                    dayNumber = 0;
                    break;
                case 'Monday':
                    dayNumber = 1;
                    break;
                case 'Tuesday':
                    dayNumber = 2;
                    break;
                case 'Wednesday':
                    dayNumber = 3;
                    break;
                case 'Thursday':
                    dayNumber = 4;
                    break;
                case 'Friday':
                    dayNumber = 5;
                    break;
                case 'Saturday':
                    dayNumber = 6;
                    break;

            }
            return dayNumber;
        },

        formatDateYMD(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        formatDateTime(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            // hour =''+ d.getHours(),
            // minute =''+ d.getMinutes();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            // if (hour.length < 2) 
            // hour = '0' + hour;
            // if (minute.length < 2) 
            // minute = '0' + minute;
            d.setSeconds(0);
            var datetime = [month, day, year].join('-') + ' ' + d.toLocaleTimeString().replace(/:00/, "");
            return datetime;
        },
        DateWithMothName(date) {
            var d = new Date(date);
            const format = "DD-MMM-YYYY"
            var dateformating = moment(date).format(format);
            var finalDate = dateformating + ' ' + d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            return finalDate;  //01-Sep-2022 4:10 PM
        },
        chnageDateTimeToLocalFormats(date) {
            const format = "yyyy-MM-DDTHH:mm"
            return moment(date).format(format);
        },
        getDayname(date) {
            var weekDayName = moment(date).format('dddd');
            return weekDayName
        }



    }

}
